<template>
  <header :class="{active: navActive}">
    <h1>
      <router-link to="/">
        <img :src="require('@/assets/images/mo_logo.svg')" alt="" />
        <img :src="require('@/assets/images/mo_logo_wh.svg')" alt="" />
        <!--        <svg-->
        <!--          viewBox="0 0 283.5 50.6"-->
        <!--          x="0"-->
        <!--          y="0"-->
        <!--          xml:space="preserve"-->
        <!--          xmlns="http://www.w3.org/2000/svg"-->
        <!--        >-->
        <!--          <path-->
        <!--            class="logo-emotion"-->
        <!--            d="M143.6 15.6c-9.7 0-17.5 7.8-17.5 17.5s7.8 17.5 17.5 17.5 17.5-7.8 17.5-17.5c0-9.6-7.8-17.5-17.5-17.5m.4 24.6c-3.9 0-7-3.2-7-7 0-3.9 3.2-7 7-7 3.9 0 7 3.2 7 7s-3.1 7-7 7M226.5 15.6c-9.7 0-17.5 7.8-17.5 17.5s7.8 17.5 17.5 17.5S244 42.8 244 33.1c0-9.6-7.8-17.5-17.5-17.5m.4 24.6c-3.9 0-7-3.2-7-7 0-3.9 3.2-7 7-7 3.9 0 7 3.2 7 7s-3.1 7-7 7M109.3 30.3c0-2.5-1.2-4.5-4.4-4.5-2.9 0-5 1.9-6 3.2v21.5H88.2V30.3c0-2.5-1.2-4.5-4.4-4.5-2.8 0-4.8 1.9-5.9 3.2v21.5H67.3V17.3h10.6v4.1c1.4-1.9 5.7-4.9 11-4.9 4.8 0 8.1 2 9.4 5.9 1.9-2.9 6.3-5.9 11.5-5.9 6.1 0 10 3.2 10 10v24.1h-10.6V30.3zM169.6 50.6v-24h-5.8v-9.3h5.8v-6.8h10.5v6.8h5.8v9.3h-5.8v24M272.8 31.4c0-4.1-2.1-5.5-5.4-5.5-3.2 0-5.2 1.7-6.5 3.2v21.5h-10.6V17.3h10.6v4.1c2-2.3 5.9-4.9 11.5-4.9 7.6 0 11 4.4 11 10.6v23.6h-10.6V31.4zM58.7 33.1c0 3.9-3.2 7-7 7-3.9 0-7-3.2-7-7 0-3.9 3.2-7 7-7s7 3.1 7 7M192.2 17.3h10.6v33.3h-10.6zM204.8 7c0 3.9-3.2 7-7 7-3.9 0-7-3.2-7-7 0-3.9 3.2-7 7-7s7 3.2 7 7M18 14.6c-10 0-18 8.1-18 18 0 10 8.1 18 18 18 7.8 0 14.4-5 16.9-11.9H23.1c-1.4 1.2-3.1 1.9-5.1 1.9-3.1 0-5.8-1.8-7.1-4.4H35.7c.2-1.1.4-2.3.4-3.5 0-10.1-8.1-18.1-18.1-18.1m-7.3 14.8c1.2-2.9 4-4.9 7.3-4.9s6.1 2 7.3 4.9H10.7z"-->
        <!--          />-->
        <!--        </svg>-->
      </router-link>
    </h1>
    <button class="nav-toggle" type="button" @click="$emit('update:navActive', !navActive)">
      <span>
        메뉴
        <span></span>
      </span>
    </button>
  </header>
</template>
<script>
  export default {
    name: "AppHeader",
    data() {
      return {};
    },
    props: ["navActive"]
  };
</script>
<style lang="scss" scoped>
  header {
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    max-width: 100vw;
    height: 0;
    justify-content: space-between;
    @include globalWidth(min-width);
    h1 {
      margin-top: 30px;
      width: 82px;
      height: 15px;
      .logo-emotion {
        fill: $red;
        transition: fill 300ms ease-in-out 600ms;
      }
      a {
        position: relative;
        display: inline-block;
        width: 83px;
        height: 16px;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          transition: 0.3s ease-in-out 0.6s;
          &:first-child {
            opacity: 1;
          }
          &:nth-child(2) {
            opacity: 0;
          }
        }
      }
    }
    .nav-toggle {
      cursor: pointer;
      z-index: 15;
      position: relative;
      margin-top: 16px;
      height: 44px;
      width: 44px;
      outline: none;
      border-radius: 50%;
      border: none;
      background-color: transparent;
      transition: background-color 300ms ease-in-out 600ms;
      span,
      > span:after,
      > span:before {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        left: 50%;
        margin-left: -10px;
        border-radius: 50%;
        background-color: $red;
      }
      span {
        top: 12px;
        display: block;
        margin-top: 0;
        transition: background-color 300ms ease-in-out 600ms,
          transform 450ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0, width 300ms ease-in-out 300ms,
          height 300ms ease-in-out 300ms, top 300ms ease-in-out 300ms;
        text-indent: -9999px;
      }
      > span > span {
        margin-left: -4px;
        margin-top: 1.5px;
      }
      span:before {
        left: 24px;
        transition: background-color 300ms ease-in-out 600ms, top 100ms ease-in 600ms,
          transform 450ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0, opacity 0s ease-in 0s,
          width 300ms ease-in-out 300ms, height 300ms ease-in-out 300ms, top 300ms ease-in-out 300ms;
      }
      span:after {
        top: auto;
        left: 24px;
        bottom: -14px;
        transition: background-color 300ms ease-in-out 600ms, bottom 100ms ease-in 600ms,
          transform 450ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0, width 300ms ease-in-out 300ms,
          height 300ms ease-in-out 300ms, top 300ms ease-in-out 300ms;
      }
    }
    &.active {
      h1 {
        img {
          transition: 0.3s ease-in-out 0.3s;
          &:first-child {
            opacity: 0;
          }
          &:nth-child(2) {
            opacity: 1;
          }
        }
      }
      .nav-toggle {
        background-color: transparent;
        transition: background-color 300ms ease-in-out 300ms;
        span,
        span:after,
        span:before {
          background-color: #fff;
          width: 24px;
          height: 2px;
          border-radius: 2px;
        }
        span {
          top: 22px;
          margin-left: -12px;
          transform: rotate(45deg);
          transition: background-color 300ms ease-in-out 300ms,
            transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 300ms, width 300ms ease-in-out 300ms,
            height 300ms ease-in-out 300ms, top 300ms ease-in-out 300ms,
            left 300ms ease-in-out 300ms, all 200ms ease-in-out 200ms;
        }
        span > span {
          opacity: 0;
        }
        span:before {
          top: 0;
          opacity: 0;
          transition: background-color 300ms ease-in-out 300ms, top 100ms ease-out 300ms,
            opacity 300ms ease-out 400ms, width 300ms ease-in-out 300ms,
            height 300ms ease-in-out 300ms, top 300ms ease-in-out 300ms, all 200ms ease-in-out 200ms;
        }
        span:after {
          bottom: 0;
          left: 10px;
          transform: rotate(90deg);
          transition: background-color 300ms ease-in-out 300ms, bottom 100ms ease-out 300ms,
            transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 300ms, width 300ms ease-in-out 300ms,
            height 300ms ease-in-out 300ms, left 300ms ease-in-out 300ms,
            all 200ms ease-in-out 200ms;
        }
      }
    }
  }
</style>
